export const tableCol = [
  {
    prop: 'changeTime',
    label: '晋级时间'
  },
  {
    prop: 'memberLevelId',
    label: '变更后会员等级'
  },
  {
    prop: 'changeReason',
    label: '变更方式'
  }
]
